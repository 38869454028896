var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col m10 offset-m1"},[_c('filter-box'),_c('div',{staticClass:"card card-content"},[(_vm.loading)?_c('div',{staticClass:"row center"},[_c('loading')],1):_c('div',[_c('div',{staticClass:"right"},[_c('h6',[_vm._v("Total "+_vm._s(_vm.data_count))])]),_c('table',{staticClass:"app-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.data),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(_vm.$route.query.page > 1 ? (_vm.$route.query.page - 1) * 10 + (index + 1) : index + 1)+" ")]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.age))]),_c('td',[_vm._v(_vm._s(item.gender == 1 ? "Male" : "Female"))]),_c('td',[_vm._v(_vm._s(item.phone))]),_c('td',[(item.patient != null)?_c('span',[_vm._v(_vm._s(item?.patient?.tb_conclusion.union_temporary_code))]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.volunteer ? item.volunteer.name : "")+" ("+_vm._s(item.volunteer ? item.volunteer.code : "")+") ")]),_c('td',[_vm._v(" "+_vm._s(parseInt(item.dots_app_status) === 0 ? "No" : "Yes")+" ")]),_c('td',[_vm._v(_vm._s(_vm.getReferForm(item)))]),_c('td',[(item.last_date)?_c('span',[_vm._v(_vm._s(item.last_date.date))]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.reported_this_month == true ? "Yes" : "No"))]),_c('td',[_vm._v(_vm._s(_vm.getLastReportDate(item)))]),_c('td',[_vm._v(_vm._s(item.dots_startdate))]),_c('td',[_vm._v(" "+_vm._s(_vm.getSupStatus( item.finish, item.dots_app_status, item.reported_this_month, item.last_reported_date ))+" ")]),_c('td',[_vm._v(_vm._s(item.dots_enddate))]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
                  (item.is_new===1 && item.treatment_outcome_id) || (item?.patient &&
                    item?.patient.tb_conclusion &&
                    item?.patient.tb_conclusion.treatment_outcome)
                ),expression:"\n                  (item.is_new===1 && item.treatment_outcome_id) || (item?.patient &&\n                    item?.patient.tb_conclusion &&\n                    item?.patient.tb_conclusion.treatment_outcome)\n                "}]},[_vm._v(" "+_vm._s(_vm.getTreatmentOut( item.is_new==1?item.treatment_outcome_id:item.patient ? item?.patient.tb_conclusion.treatment_outcome : 0 ))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
                   (item.is_new===1 && !item.treatment_outcome_id) || (item?.patient &&
                    item?.patient?.tb_conclusion &&
                    !item?.patient?.tb_conclusion.treatment_outcome)
                ),expression:"\n                   (item.is_new===1 && !item.treatment_outcome_id) || (item?.patient &&\n                    item?.patient?.tb_conclusion &&\n                    !item?.patient?.tb_conclusion.treatment_outcome)\n                "}]},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.finish),expression:"item.finish"}],staticClass:"red-text",staticStyle:{"font-size":"14px"}},[_vm._v("Required")])]),(
                    (item.is_new===1 && item.treatment_outcome_id) || (item?.patient &&
                    item?.patient?.tb_conclusion &&
                    item?.patient?.tb_conclusion.treatment_outcome)
                )?_c('td',[_vm._v(" "+_vm._s(item.is_new==1?item.outcome_date:item?.patient?.tb_conclusion.outcome_date)+" ")]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
                   (item.is_new===1 && !item.treatment_outcome_id) || (item?.patient &&
                    item.patient?.tb_conclusion &&
                    !item.patient?.tb_conclusion.treatment_outcome)
                ),expression:"\n                   (item.is_new===1 && !item.treatment_outcome_id) || (item?.patient &&\n                    item.patient?.tb_conclusion &&\n                    !item.patient?.tb_conclusion.treatment_outcome)\n                "}]},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.finish),expression:"item.finish"}],staticClass:"red-text",staticStyle:{"font-size":"14px"}},[_vm._v("Required")])]),_c('td',[(item.patient !== null)?_c('router-link',{staticClass:"btn blue darken-2",attrs:{"to":'/patient/dots/edit/' + item.id}},[_vm._v("View ")]):_c('router-link',{staticClass:"btn blue darken-2",attrs:{"to":'/patient/dots-only/edit/' + item.id}},[_vm._v("Detail")])],1)])}),0)])]),(!_vm.loading && _vm.data.length < _vm.data_count)?_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px","text-align":"center"}},[_c('ul',{staticClass:"pagination"},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.page > 1),expression:"$route.query.page > 1"}]},[_c('span',{on:{"click":function($event){_vm.onPageChange(parseInt(_vm.$route.query.page) - 1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_left")])])]),_vm._l((_vm.pages),function(page){return _c('li',{key:`dots-paginated-page-${page}`,class:`${_vm.$route.query.page == page ? 'active blue' : 'waves-effect'}`},[_c('a',{on:{"click":function($event){return _vm.onPageChange(page)}}},[_vm._v(_vm._s(page))])])}),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.page < _vm.pages),expression:"$route.query.page < pages"}],staticClass:"waves-effect"},[_c('a',{on:{"click":function($event){_vm.onPageChange(parseInt(_vm.$route.query.page) + 1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_right")])])])],2)]):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Age")]),_c('th',[_vm._v("Gender")]),_c('th',[_vm._v("Phone")]),_c('th',[_vm._v("Union Temporary Code")]),_c('th',[_vm._v("Volunteer")]),_c('th',[_vm._v("Use App")]),_c('th',[_vm._v("Refer From")]),_c('th',[_vm._v("Last Visit Date")]),_c('th',[_vm._v("Reported")]),_c('th',[_vm._v("Last Reported Date")]),_c('th',[_vm._v("DOTS Startdate")]),_c('th',[_vm._v("Completed DOT supervision (Y/N)")]),_c('th',[_vm._v("DOTS Enddate")]),_c('th',[_vm._v("Treatment Outcome")]),_c('th',[_vm._v("Outcome Date")]),_c('th',[_vm._v("Actions")])])])
}]

export { render, staticRenderFns }