<template>
  <div class="row">
    <div class="col m8 offset-m2">
      <section>
        <div>
          <back-container link="/patient/dots/list"></back-container>
          <setting-bar></setting-bar>
        </div>
        <div class="card card-content">
          <h6>Presumptive Information</h6>

          <div class="field">
            <span>Name:</span>
            <p>{{ data?.name || '' }}</p>
          </div>
          <div class="field">
            <span>Phone:</span>
            <p>{{ data?.phone || '' }}</p>
          </div>
          <div class="field">
            <span>Age:</span>
            <p>{{ data?.age || '' }}</p>
          </div>
          <div class="field">
            <span>Gender:</span>
            <p>{{ data?.gender == 1 ? 'Male' : 'Female' }}</p>
          </div>
          <div class="field">
            <span>Address:</span>
            <p>{{ data?.address || '' }}</p>
          </div>
          <div class="field">
            <span>Weight:</span>
            <p>{{ data?.weight || '' }}</p>
          </div>
          <div class="field">
            <span>Volunteer:</span>
            <p v-if="data?.volunteer_id">
              {{ data?.volunteers.find((v) => v.id === data.volunteer_id)?.name || 'N/A' }}
            </p>
          </div>
          <div class="field">
            <span>Actual Treatment Start Date:</span>
            <p>{{ data?.actual_treatment_startdate || '' }}</p>
          </div>
          <div class="field">
            <span>Treatment End Date:</span>
            <p>{{ data?.treatment_enddate || '' }}</p>
          </div>

          <div class="field" v-if="data?.is_new === 0">
            <span>Treatment Outcome:</span>
            <p>
              {{
                {
                  '1': 'Cured',
                  '2': 'Treatment complete',
                  '3': 'Treatment failure',
                  '4': 'Died',
                  '5': 'Lost to follow up',
                  '6': 'Not evaluated',
                  '7': 'Moved to second line treatment',
                }[data.patient?.tb_conclusion?.treatment_outcome] || 'N/A'
              }}
            </p>
          </div>
          <div class="field" v-if="data?.is_new === 1">
            <span>Treatment Outcome:</span>
            <p>
              {{
                {
                  '1': 'Cured',
                  '2': 'Treatment complete',
                  '3': 'Treatment failure',
                  '4': 'Died',
                  '5': 'Lost to follow up',
                  '6': 'Not evaluated',
                  '7': 'Moved to second line treatment',
                }[data?.treatment_outcome_id] || ''
              }}
            </p>
          </div>

          <div class="field" v-if="data?.project_id">
            <span>Township:</span>
            <p>{{ townships.find((t) => t.id === data?.other_township_id)?.name || 'N/A' }}</p>
          </div>
          <div class="field">
            <span>DOTS End Date:</span>
            <p>{{ data?.dots_enddate || '' }}</p>
          </div>
          <div class="field">
            <span>Using DOTS APP:</span>
            <p>{{ data?.dots_app_status == '1' ? 'Yes' : 'No' }}</p>
          </div>
          <div class="field">
            <span>Type of Form Received:</span>
            <p>{{ data?.dots_form_type || '' }}</p>
          </div>
          <div class="field">
            <span>Remark:</span>
            <p>{{ data?.remark || '' }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
import SettingBar from "./SettingBar.vue";

export default {
  mounted() {
    this.$store.dispatch("getDOTSPatientEditData", {
      id: this.$route.params.id,
    });
  },
  computed: {
    data() {
      return this.$store.getters.dots_initial_data;
    },
    townships() {
      return this.$store.getters.townships || [];
    },
  },
  components: {
    "back-container": BackContainer,
    "setting-bar": SettingBar,
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
.field {
  margin-bottom: 15px;
}
.field span {
  font-weight: bold;
}
.field p {
  margin: 0;
}
</style>
