<template>
  <div class="row">
    <div class="col m10 offset-m1">
      <filter-box></filter-box>
      <div class="card card-content">
        <div class="row center" v-if="loading">
          <loading></loading>
        </div>
        <div v-else>
          <div class="right">
            <h6>Total {{ data_count }}</h6>
          </div>
          <table class="app-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Phone</th>
                <th>Union Temporary Code</th>
                <th>Volunteer</th>
                <th>Use App</th>
                <th>Refer From</th>
                <th>Last Visit Date</th>
                <th>Reported</th>
                <th>Last Reported Date</th>
                <th>DOTS Startdate</th>
                <th>Completed DOT supervision (Y/N)</th>
                <th>DOTS Enddate</th>
                <th>Treatment Outcome</th>
                <th>Outcome Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" v-bind:key="item.id">
                <td>
                  {{
                    $route.query.page > 1
                      ? ($route.query.page - 1) * 10 + (index + 1)
                      : index + 1
                  }}
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.age }}</td>
                <td>{{ item.gender == 1 ? "Male" : "Female" }}</td>
                <td>{{ item.phone }}</td>

                <td>
                  <span v-if="item.patient != null">{{
                    item?.patient?.tb_conclusion.union_temporary_code
                  }}</span>
                </td>
                <td>
                  {{ item.volunteer ? item.volunteer.name : "" }} ({{
                    item.volunteer ? item.volunteer.code : ""
                  }})
                </td>
                <td>
                  {{ parseInt(item.dots_app_status) === 0 ? "No" : "Yes" }}
                </td>
                <td>{{ getReferForm(item) }}</td>

                <td>
                  <span v-if="item.last_date">{{ item.last_date.date }}</span>
                </td>
                <td>{{ item.reported_this_month == true ? "Yes" : "No" }}</td>
                <td>{{ getLastReportDate(item) }}</td>
                <td>{{ item.dots_startdate }}</td>
                <td>
                  {{
                    getSupStatus(
                      item.finish,
                      item.dots_app_status,
                      item.reported_this_month,
                      item.last_reported_date
                    )
                  }}
                </td>
                <td>{{ item.dots_enddate }}</td>

               <td
                  v-show="
                    (item.is_new===1 && item.treatment_outcome_id) || (item?.patient &&
                      item?.patient.tb_conclusion &&
                      item?.patient.tb_conclusion.treatment_outcome)
                  "
                >
                  {{
                    getTreatmentOut(
                      item.is_new==1?item.treatment_outcome_id:item.patient
                        ? item?.patient.tb_conclusion.treatment_outcome
                        : 0
                    )
                  }}
                </td>

                <td
                  v-show="
                     (item.is_new===1 && !item.treatment_outcome_id) || (item?.patient &&
                      item?.patient?.tb_conclusion &&
                      !item?.patient?.tb_conclusion.treatment_outcome)
                  "
                >
                  <span
                    v-show="item.finish"
                    class="red-text"
                    style="font-size:14px;"
                    >Required</span
                  >
                </td>
                <td
                  v-if="
                      (item.is_new===1 && item.treatment_outcome_id) || (item?.patient &&
                      item?.patient?.tb_conclusion &&
                      item?.patient?.tb_conclusion.treatment_outcome)
                  "
                >
                  {{ item.is_new==1?item.outcome_date:item?.patient?.tb_conclusion.outcome_date }}
                </td>
                 <td
                  v-show="
                     (item.is_new===1 && !item.treatment_outcome_id) || (item?.patient &&
                      item.patient?.tb_conclusion &&
                      !item.patient?.tb_conclusion.treatment_outcome)
                  "
                >
                  <span
                    v-show="item.finish"
                    class="red-text"
                    style="font-size:14px;"
                    >Required</span
                  >
                </td>
                <td>
                  <router-link
                    v-if="item.patient !== null"
                    :to="'/patient/dots/edit/' + item.id"
                    class="btn blue darken-2"
                    >View </router-link
                  >
                  <router-link
                    v-else
                    :to="'/patient/dots-only/edit/' + item.id"
                    class="btn blue darken-2"
                    >Detail</router-link
                  >
                </td> 
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="row"
          style="margin-top: 20px;text-align:center;"
          v-if="!loading && data.length < data_count"
        >
          <ul class="pagination">
            <li v-show="$route.query.page > 1">
              <span @click="onPageChange(parseInt($route.query.page) - 1)"
                ><i class="material-icons">chevron_left</i></span
              >
            </li>
            <li
              v-for="page in pages"
              v-bind:key="`dots-paginated-page-${page}`"
              v-bind:class="
                `${$route.query.page == page ? 'active blue' : 'waves-effect'}`
              "
            >
              <a @click="onPageChange(page)">{{ page }}</a>
            </li>
            <li class="waves-effect" v-show="$route.query.page < pages">
              <a @click="onPageChange(parseInt($route.query.page) + 1)"
                ><i class="material-icons">chevron_right</i></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import FilterBox from "./FilterBox";
import moment from "moment";
export default {
  name: "DOTS Patient List",
  mounted() {
    if (!this.$route.query.page) {
      this.$router.push("?page=1");
    }
    this.getData();
  },
  components: {
    loading: Loading,
    "filter-box": FilterBox,
  },
  computed: {
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    data() {
      return this.$store.getters.dots_data;
    },
    pages() {
      return this.$store.getters.dots_page;
    },
    data_count() {
      return this.$store.getters.dots_data_count;
    },
  },
  methods: {
    loadMore() {
      this.getData(true);
    },
    getLastReportDate(report) {
      if (report.dots_app_status == 0) {
        if (report.last_reported_date)
          return report.last_reported_date.created_at.substr(0, 10);
      } else if (report.dots_app_status == 1) {
        if (report.last_reported_date) return report.last_reported_date.date;
      }
    },
    getTreatmentOut(id) {
      var result = null;
      var value = parseInt(id);
      switch (value) {
        case 1:
          result = "Cured";
          break;
        case 2:
          result = "Treatment complete";
          break;
        case 3:
          result = "Treatment failure";
          break;
        case 4:
          result = "Died";
          break;
        case 5:
          result = "Lost to follow up";
          break;
        case 6:
          result = "Not Evaluated";
          break;
        case 7:
          result = "Moved to second line treatment";
          break;
      }
      return result;
    },
    onPageChange(page) {
      var query = this.$route.query;
      query.page = page;

      window.location = `/patient/dots/list?${new URLSearchParams(
        query
      ).toString()}`;
    },
    getData() {
      var data = {
        nowMonth: true,
      };

      if (this.$route.query.filter) {
        data.filter = this.$route.query.filter;
      }

      if (this.$route.query.month && this.$route.query.year) {
        data.month = this.$route.query.month;
        data.year = this.$route.query.year;
      }

      if (this.$route.query.name) {
        data.name = this.$route.query.name;
      }

      if (this.$route.query.volunteer_id) {
        data.volunteer_id = this.$route.query.volunteer_id;
      }

      if (this.$route.query.startDateFrom && this.$route.query.startDateTo) {
        data.startDateFrom = this.$route.query.startDateFrom;
        data.startDateTo = this.$route.query.startDateTo;
      }

      if (this.$route.query.endDateFrom && this.$route.query.endDateTo) {
        data.endDateFrom = this.$route.query.endDateFrom;
        data.endDateTo = this.$route.query.endDateTo;
      }

      if (this.$route.query.page) {
        data.page = this.$route.query.page;
      }

      this.$store.dispatch("getDOTSPatient", data);
      this.$store.dispatch("getVolunteer", {
        all: true,
      });
    },
    getSupStatus(status, app_status, reported, reportedDate) {
      if (reported) {
        var text;
        if (status == 1) text = "No";
        else if (status == 2) text = "Yes";
        else if (app_status == 0 && status == null && reported)
          text = "On-Going";
        else if (app_status == 1) text = "On Going";

        return text;
      } else {
        if (status == 1) text = "No";
        else if (status == 2) text = "Yes";
        return text;
      }
    },
    getSupDate(status, app_status, reported, reportedDate, dotsEndDate) {
      if (reported) {
        var text;
        if (app_status == 0)
          text = `${
            reportedDate
              ? moment(reportedDate.created_at).format("yyyy-M-D")
              : ""
          }`;
        else if (app_status == 1)
          text = ` ${reportedDate ? reportedDate.date : ""}`;
        return text;
      } else {
        if (status == 1) text = dotsEndDate;
        else if (status == 2) text = dotsEndDate;
        return text;
      }
    },
    getReferForm(item) {
      if (parseInt(item.is_new) == 0) {
        if (item.patient) {
          switch (parseInt(item.patient.metadata.refer_from)) {
            case 1:
              return "PICTS volunteer";
              break;
            case 2:
              return "PICTS GP";
              break;
            case 3:
              return "PICTS pharmacy";
              break;
            case 4:
              return "Others";
              break;
            case 5:
              return "Township";
              break;
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
</style>
